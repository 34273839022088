/**
 *
 * Validators for ForgotPasswordFormik
 *
 *
 */

export default values => {
  const errors = {};

  switch (true) {
    case !values.email:
      errors.email = 'Required';
      break;
    case !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email):
      errors.email = 'Not a valid email.';
      break;
    default:
  }

  return errors;
};
