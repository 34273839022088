/**
 *
 * Ripspot Modal
 *
 *
 */

import React, { memo, useState, useEffect } from 'react';
import { connect, batch } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import { closeRipspotModal, setRipspotCurrentStep } from 'actions/modals';

import Modal from 'components/modals/index';
import RipspotCreation from 'containers/RipspotCreation';
import { steps, editSteps } from 'containers/RipspotCreation/steps';
import AnimatedTitle from 'components/AnimatedTitle';

import shouldShowRandomPins from 'containers/ExploreMap/shouldShowRandomPins';

import {
  setIsInNewRipspotMode,
  setViewSingleRipspot,
  setPublicPins,
  setIsInExistingPinEditMode,
  removeCurrentRipspot
} from 'actions/ripspots';

import {
  REVIEW,
  PHOTO_GALLERY,
  DETAILS,
  // BIO,
  PAYMENT,
  SHARE,
} from 'utils/constants';
import {
  MAP_CR_LOCATIONS_ROUTE,
  MAP_CR_CONFIRMATION_ROUTE,
  MAP_SEARCH_ROUTE,
} from 'utils/routes';

const RipspotModal = ({
  isOpen,
  dispatch,
  metadata,
  currentStep,
  prevStep,
  ripspots,
}) => {
  //
  //
  //
  //
  //
  //
  const [shouldClearTempData, setShouldClearTempData] = useState(false);
  const [isOnDetails, setIsOnDetails] = useState(false);
  const [stepsToUse, setParentStepsToUse] = useState(
    (metadata && metadata.isInNewRipspotMode) ||
      (ripspots && ripspots.isInNewRipspotMode)
      ? steps
      : editSteps,
  );

  const onRequestClose = () => {
    if (currentStep === 6) {
      // reset currentStep
      setShouldClearTempData(true);
      dispatch(setIsInNewRipspotMode(false));
      dispatch(setViewSingleRipspot(false));
      dispatch(setIsInExistingPinEditMode(false));
      dispatch(removeCurrentRipspot());
      console.log("Refreshing Map after close!");
      shouldShowRandomPins({
        dispatch,
        setPublicPins,
        limit: 100,
        query: {}, // optional
      });
    }

    if (window.location.pathname === MAP_CR_CONFIRMATION_ROUTE) {
      navigate(MAP_SEARCH_ROUTE);
    } else if (window.location.pathname.includes('/map/create-ripspot')) {
      navigate(MAP_CR_LOCATIONS_ROUTE);
    }

    batch(() => {
      dispatch(closeRipspotModal());
      dispatch(setRipspotCurrentStep(1));
    });
  };

  useEffect(() => {
    if (!isOpen) {
      // clear this prop so next time we create a ripspot the tempData is not cleared
      setShouldClearTempData(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (stepObjKey === 'details') {
      setIsOnDetails(true);
    } else {
      setIsOnDetails(false);
    }
  }, [currentStep]);

  //
  //
  //
  //
  //
  //
  //
  //
  //

  const stepObjKey = stepsToUse[currentStep].key;

  const isReview = stepObjKey === REVIEW;
  const isPhotoGallery = stepObjKey === PHOTO_GALLERY;
  const isShare = stepObjKey === SHARE;
  const isPayment = stepObjKey === PAYMENT;

  return (
    <Modal
      noMinMax
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      isPayment={isPayment}
      isReview={isReview}
      // width="calc(100vw - 30px)"
      // height="calc(100vh - 40px)"
      maxWidth={isOnDetails ? '600px' : ''}
      width={
        isShare
          ? 'calc(95vw - 60px)'
          : isReview || isPhotoGallery
          ? 'calc(100vw - 30px)'
          : 'calc(65vw - 50px)'
      }
      height={
        isShare
          ? 'calc(95vh - 80px)'
          : isReview || isPhotoGallery
          ? 'calc(95vh - 40px)'
          : 'calc(75vh - 60px)'
      }
      renderTitle={() => (
        <AnimatedTitle
          // animateUpDown
          includeStep={false}
          step={currentStep}
          prevStep={prevStep}
          steps={stepsToUse}
          isReview={isReview}
          isPayment={isPayment}
        />
      )}
      isRipspotModal
    >
      <Container>
        <RipspotCreation
          shouldClearTempData={shouldClearTempData}
          setShouldClearTempData={setShouldClearTempData}
          metadata={metadata}
          setParentStepsToUse={setParentStepsToUse}
        />
      </Container>
    </Modal>
  );
};

RipspotModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  metadata: PropTypes.object,
  ripspots: PropTypes.object,
};

const mapStateToProps = ({ display, modals, ripspots }) => ({
  display,
  isOpen: modals.ripspotModal.isOpen,
  metadata: modals.ripspotModal.metadata,
  currentStep: modals.ripspotModal.currentStep,
  prevStep: modals.ripspotModal.prevStep,
  ripspots,
});

const withConnect = connect(mapStateToProps);
export default compose(memo, withConnect)(RipspotModal);

const Container = styled.div`
  height: 100%;
`;
