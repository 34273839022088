/**
 *
 * Details Formik
 *
 *
 */
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import SimpleDatePicker from 'components/SimpleDatePicker';
import { FormWrapper, StyledField } from 'components/lib/Formik';

// const options = [
//   { value: 'army', label: 'Army' },
//   { value: 'navy', label: 'Navy' },
//   { value: 'airForce', label: 'Air Force' },
//   { value: 'marines', label: 'Marines' },
//   { value: 'coastGuard', label: 'Coast Guard' },
//   { value: 'navySeal', label: 'Navy Seals' },
// ];

const handleSetTempData = ({ setTempData, getTempData, field, value }) => {
  setTempData('details', {
    ...getTempData().details,
    [field]: value,
  });
};

/**
 * Updates the date selector value and also updates tempData for re-navigation data retention
 *
 * @param {*} {
 *   fieldName,
 *   type,
 *   value,
 *   values,
 *   setFieldValue,
 *   setTempData,
 *   getTempData,
 *   dispatch,
 * }
 */
const handleNewDate = ({
  fieldName,
  type,
  value,
  values,
  setFieldValue,
  setTempData,
  getTempData,
  dispatch,
}) => {
  // update birthDate with whatever has changed

  //
  // form new date obj
  const newDate = {
    ...values[fieldName],
    [type]: value,
  };

  //
  //
  //
  //
  //
  //

  // now set formik state
  setFieldValue(fieldName, newDate);
  // and tempData for data
  // retention if user navigates mid-edit
  // (init-data --> tempData is out of react render cycle
  // so no infinite looping when setting tempData
  // even tho enableReinitialize prop on Formik is set to true
  // which updates formik when init values change
  // --> only "react" values trigger this aka useState vars)
  handleSetTempData({
    setTempData,
    getTempData,
    dispatch,
    field: fieldName,
    value: newDate,
  });
  //
  // const newTempDataDetails = getTempData().details;
  //

  // setTimeout(() => {
  //
  //   const newTempDataDetailsdataa = getTempData();
  //
  // }, 2000);
};

/**
 * Clears the date selector input
 *
 * @param {*} {
 *   fieldName,
 *   values,
 *   dateType,
 *   setTempData,
 *   getTempData,
 *   dispatch,
 *   setFieldValue,
 * }
 */
const clearDateTypeSelector = ({
  fieldName,
  values,
  dateType,
  setTempData,
  getTempData,
  dispatch,
  setFieldValue,
}) => {
  // day, month, or year

  const newDate = {
    ...values[fieldName],
    [dateType]: null, // day, month, year
  };
  //
  //
  setFieldValue(fieldName, newDate);
  handleSetTempData({
    setTempData,
    getTempData,
    dispatch,
    field: fieldName,
    value: newDate,
  });

  // setTimeout(() => {
  //
  //   const dataa = getTempData();
  //
  // }, 2000);
};

const DetailsFormik = ({
  initialValues,
  validate,
  onSubmit,
  setTempData,
  getTempData,
  dispatch,
  setDisableContinue,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        // eslint-disable-next-line
        ...rest
      }) => {
        //
        //
        return (
          <Form style={{ position: 'relative' }}>
            <FormWrapper>
              {/* <Spinner loading={isSubmitting} cover /> */}
              <FieldWrapper>
                <FieldLabel>Full Name</FieldLabel>
                <StyledField
                  // useAnimated
                  type="text"
                  name="name"
                  placeholder=""
                  touched={touched}
                  errors={errors}
                  value={values.name}
                  onBlur={() => setFieldTouched('name', true)}
                  onChange={evt => {
                    const { value } = evt.target;
                    setFieldValue('name', value);
                    handleSetTempData({
                      setTempData,
                      getTempData,
                      dispatch,
                      field: 'name',
                      value,
                    });
                  }}
                  wrapperStyle={{
                    marginTop: '5px',
                  }}
                />
              </FieldWrapper>
              <DateFields style={{ width: '100%' }}>
                <div>
                  <FieldLabel>
                    Date of Birth <Optional>(optional)</Optional>
                  </FieldLabel>
                  <SimpleDatePicker
                    id="birthDate"
                    initDay={initialValues.birthDate.day}
                    initMonth={initialValues.birthDate.month}
                    initYear={initialValues.birthDate.year}
                    errors={errors}
                    onBlur={() => setFieldTouched('birthDate', true)}
                    updateDate={(type, value) => {
                      // UPDATES BIRTH DATE
                      handleNewDate({
                        fieldName: 'birthDate',
                        type,
                        value,
                        values,
                        setFieldValue,
                        setTempData,
                        getTempData,
                        dispatch,
                      });
                    }}
                    onClearValue={dateType => {
                      // CLEARS BIRTH DATE
                      clearDateTypeSelector({
                        fieldName: 'birthDate',
                        values,
                        dateType,
                        setTempData,
                        getTempData,
                        dispatch,
                        setFieldValue,
                      });
                    }}
                  />
                </div>

                <div style={{ marginTop: '25px' }}>
                  <FieldLabel>
                    Date of Death<Optional>(optional)</Optional>
                  </FieldLabel>
                  <SimpleDatePicker
                    id="deathDate"
                    initDay={initialValues.deathDate.day}
                    initMonth={initialValues.deathDate.month}
                    initYear={initialValues.deathDate.year}
                    errors={errors}
                    onBlur={() => setFieldTouched('deathDate', true)}
                    updateDate={(type, value) => {
                      // UPDATES DEATH DATE
                      handleNewDate({
                        fieldName: 'deathDate',
                        type,
                        value,
                        values,
                        setFieldValue,
                        setTempData,
                        getTempData,
                        dispatch,
                      });
                    }}
                    onClearValue={dateType => {
                      // CLEARS DEATH DATE
                      clearDateTypeSelector({
                        fieldName: 'deathDate',
                        values,
                        dateType,
                        setTempData,
                        getTempData,
                        dispatch,
                        setFieldValue,
                      });
                    }}
                  />
                </div>
              </DateFields>
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

DetailsFormik.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  setTempData: PropTypes.func.isRequired,
  getTempData: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(DetailsFormik);

const DateFields = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  > div {
    &:first-of-type {
      margin-right: 10px;
    }
  }
`;

const FieldWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const FieldLabel = styled.label`
  color: ${({ theme }) => theme.colors.darkGrey};
  display: flex;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const Optional = styled.div`
  opacity: 0.65;
  font-size: 0.8rem;
  margin-left: 10px;
`;

// // update birthDate with whatever has changed
//
// //
// // form new date obj
// const newBirthDate = {
//   ...values.birthDate,
//   [type]: value,
// };
//
// //
// // now set formik state
// setFieldValue('birthDate', newBirthDate);
// // and tempData for data
// // retention if user navigates mid-edit
// // (init-data --> tempData is out of react render cycle
// // so no infinite looping when setting tempData
// // even tho enableReinitialize prop on Formik is set to true
// // which updates formik when init values change
// // --> only "react" values trigger this aka useState vars)
// handleSetTempData({
//   setTempData,
//   getTempData,
//   dispatch,
//   field: 'birthDate',
//   value: newBirthDate,
// });

//
//

// // day, month, or year
// const newBirthDate = {
//   ...values.birthDate,
//   [dateType]: null, // day, month, year
// };
//
//
// setFieldValue('deathDate', newBirthDate);
// handleSetTempData({
//   setTempData,
//   getTempData,
//   dispatch,
//   field: 'deathDate',
//   value: newBirthDate,
// });
