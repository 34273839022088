/* eslint-disable prefer-destructuring */
/**
 *
 * PhotoCard
 *
 *
 */

import React, { useState, useEffect, useRef } from 'react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import uuid from 'uuid';

import Button from 'components/Elements/Button';
import CloseIcon from 'components/icons/Close';
import { PlusThick } from 'components/icons/Plus';
import Edit from 'components/icons/Edit';
// import { PlusCirlce } from 'components/icons/Plus';
import DropZone from 'components/FileUpload/DropZone';
import Spinner from 'components/Spinner';

import { validateFiles } from 'utils/helperFunctions/images';

import { proccessFile } from './processPhotoUtils';

/**
 * PhotoCard
 *
 * @param {*} { initialPhotoData }
 * @param {*} { initialPhotoText }
 * @param {*} { onSave }
 *
 * @returns
 */
const PhotoCard = ({
  initialPhotoData,
  initialPhotoText,
  onSave,
  onDelete,
  indexKey,
}) => {
  const textarea = useRef();

  const [id] = useState(uuid());
  const [photoText, setPhotoText] = useState(initialPhotoText || '');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  // * NEW
  const [isLoading, setIsLoading] = useState(false);

  const [fileData, setFileData] = useState(initialPhotoData || null);
  const [hoverWithFile, setHoverWithFile] = useState(false);

  useEffect(() => {
    //
    if (initialPhotoData) {
      setFileData(initialPhotoData);
    }
    if (initialPhotoText) {
      setPhotoText(initialPhotoText);
    }
  }, [initialPhotoData, initialPhotoText]);

  const handleTextChange = evt => {
    const { value } = evt.target;
    setPhotoText(value);
  };

  const handleBoxClick = evt => {
    if (!isEditMode && fileData) {
      // if image, preventDefault
      evt.preventDefault();
    }
  };

  /**
   * Handles image file input
   *
   * @param {*} evt
   */
  const handleFile = async evt => {
    const { files } = evt.target;

    const file = files[0];

    const res = validateFiles(files);

    if (fileData) return;

    if (file) {
      setIsLoading(true);
      const isJPEG = file.type.includes('jpeg');
      const isAlsoJPEG = file.type.includes('jpg');
      const isPNG = file.type.includes('png');

      // const bytes = formatBytes(file.size);
      //
      //
      // const units = formatSizeUnits(file.size);
      //
      //

      const imageType = isPNG
        ? 'PNG'
        : ((isJPEG || isAlsoJPEG) && 'JPEG') || '';

      // just in case, return if no image type that we accept...Are we going to want to allow SVGs for uploads? No resize needed, nature of svg is scale
      if (!imageType) return;

      try {
        const data = await proccessFile(file);

        // set new file data in local state after images are resized to prep for upload
        setFileData(data);
        setIsEditMode(true);
        setIsLoading(false);
      } catch (err) {
        console.error(err);

        setIsLoading(false);
      }
    }
  };

  const handleCloseEditClick = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsEditMode(!isEditMode);
  };

  const handleDelete = evt => {
    setFileData(null);
    setFileData(null);
    setIsEditMode(false);
    onDelete(indexKey); // we dont actually need this indexKey, we have context in parent container still
  };

  const handleSave = evt => {
    setIsEditMode(false);
    onSave({ fileData, photoText, isProfile });
  };

  useEffect(() => {
    // focuses the textarea when a user goes to edit an image in the gallery
    if (isEditMode && textarea.current) {
      textarea.current.focus();
    }
  }, [isEditMode]);

  //
  // UI render logic -- quick fix aned cleanup for when we are using s3 urls and not file data from new images,
  // but both files and existing image urls now work the same for the user
  //
  // const showImageWithNoFile = fileData && fileData.imageSrc && !fileData.file;
  const showEditIcon = fileData && fileData.imageSrc; // (fileData.file || showImageWithNoFile);
  const showAddImage = !fileData || !fileData.imageSrc; // (!fileData || !fileData.file) && !showImageWithNoFile;
  const showPhotoText = !isEditMode && fileData && fileData.imageSrc; // (fileData.file || showImageWithNoFile);

  return (
    <Container>
      {/* <SpinnerWrapper isLoading={isLoading}>
        <Spinner loading={isLoading} />
      </SpinnerWrapper> */}
      <Content isEditMode={isEditMode}>
        <Box onClick={handleBoxClick} isEditMode={isEditMode}>
          {showEditIcon && (
            <EditCloseWrapper
              onClick={handleCloseEditClick}
              isEditMode={isEditMode}
              isEditIcon={!isEditMode}
            >
              <SVGWrapper isEditIcon={!isEditMode}>
                {isEditMode ? (
                  <CloseIcon />
                ) : (
                  <Edit width="18px" height="18px" />
                )}
              </SVGWrapper>
            </EditCloseWrapper>
          )}
          <DropZone
            withHiddenInput
            accept=".png,.jpg,.jpeg" // .svg --> currently not able to work with resize lib, we can always just allow them and add logic to account for svg and avoid the resize image function all together
            onDrop={handleFile}
            hoverWithFile={hoverWithFile}
            setHoverWithFile={setHoverWithFile}
            onInputChange={handleFile}
            inputId={id}
          >
            {showAddImage ? (
              <AddFileFlexCentered hoverWithFile={hoverWithFile}>
                <SVGWrapperBig>
                  <PlusThick />
                </SVGWrapperBig>
                <p style={{ textAlign: 'center' }}>
                  Select or drag image to upload
                </p>
              </AddFileFlexCentered>
            ) : (
              <ImageWrapper id={`image-${id}`} style={{}}>
                <img src={fileData.imageSrc} alt="" />
              </ImageWrapper>
            )}
            {showPhotoText && (
              <ImageLabel htmlFor={`image-${id}`}>
                <PhotoText>{photoText}</PhotoText>
              </ImageLabel>
            )}
          </DropZone>
        </Box>
        {isEditMode && (
          <EditWrapper>
            <Textarea
              ref={textarea}
              value={photoText}
              onChange={handleTextChange}
            />
            {/* <label htmlFor={`checkbox-${id}`}>
              <input
                id={`checkbox-${id}`}
                type="checkbox"
                onChange={evt => {
                  const { checked } = evt.target;
                  setIsProfile(checked);
                }}
              />
              <span>Make image profile picture.</span>
            </label> */}
            <Controls>
              <Button dangerous onClick={handleDelete}>
                Delete
              </Button>
              <Button light onClick={handleSave}>
                Save
              </Button>
            </Controls>
          </EditWrapper>
        )}
      </Content>
    </Container>
  );
};

PhotoCard.propTypes = {
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  indexKey: PropTypes.string,
  initialPhotoData: PropTypes.any,
  initialPhotoText: PropTypes.string,
};

export default PhotoCard;

const Container = styled.div`
  position: relative;
  height: 100%;
  padding: 15px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;

  ${({ theme, isLoading }) => theme.styles.showhideStyles(isLoading)};
`;

//
// TODO: uncomment the overflow code to start working on fix for Save button overflow style bug
//
const Content = styled.div`
  height: 100%;
  cursor: pointer;
  /* overflow-y: scroll;
  overflow-x: hidden; */

  ${({ isEditMode }) =>
    isEditMode &&
    css`
      ${({ theme }) => theme.styles.boxShadows['1']};
      padding: 10px;
    `}
`;

const Box = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  /* max-height: 190px; */
  margin: 0 auto;
  cursor: pointer;
  ${({ theme }) => theme.media.min.tablet`
    height: 100%;
    /* max-height: 190px; */
  `}
`;

const SVGWrapper = styled.div`
  ${({ theme }) => theme.styles.middle}
  width: 30px;
  height: 30px;

  ${({ isEditIcon, theme }) =>
    isEditIcon &&
    css`
      ${theme.styles.boxShadows['1']};
      background-color: ${theme.colors.lightGreen};
      border-radius: 50%;
      svg {
        fill: #fff;
      }
    `}

  svg {
    ${({ shouldRotate }) =>
      shouldRotate &&
      css`
        transform: rotate(45deg);
      `}
  }
`;

SVGWrapper.propTypes = {
  shouldRotate: PropTypes.bool,
};

const SVGWrapperBig = styled(SVGWrapper)`
  min-width: 60px;
  min-height: 60px;
`;

const Textarea = styled.textarea`
  width: 100%;
  max-width: 100%;
  height: 100%;
  resize: none;
  padding: 3px;
  margin-top: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  ${({ theme }) => theme.media.min.tablet`
    height: auto;
  `}
  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.Primary};
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* max-height: 160px; */
  overflow: hidden;
  border: 1px solid #000;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const AddFileFlexCentered = styled.div`
  ${({ theme }) => theme.styles.grow}
  ${({ theme }) => theme.styles.middle}
  color: ${({ theme, hoverWithFile }) =>
    hoverWithFile ? 'grey' : theme.colors.Blue};
  height: 100%;
  width: 100%;
  padding: 60px 15px;
  cursor: pointer;
  border: 3px dashed
    ${({ theme, hoverWithFile }) =>
      hoverWithFile ? 'grey' : theme.colors.Blue};
  transition: color 0.3s, border-color 0.3s;

  p {
    margin-top: 35px;
    font-size: 1rem;
    opacity: 0.75;
  }
  svg {
    transition: fill 0.3s;
    fill: ${({ theme, hoverWithFile }) =>
      hoverWithFile ? 'grey' : theme.colors.Blue};
  }
`;

const EditCloseWrapper = styled.div`
  ${({ theme }) => theme.styles.middle}
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  fill: ${({ theme }) => theme.colors.Primary};
  z-index: 2;
  cursor: pointer;

  ${({ isEditMode, isEditIcon }) =>
    (isEditMode || isEditIcon) &&
    css`
      fill: #fff;
      background-color: lightgrey;
    `}

  ${({ isEditMode }) =>
    isEditMode &&
    css`
      right: -18px;
      top: -14px;
    `}

  ${({ isEditIcon }) =>
    isEditIcon &&
    css`
      right: -12px;
      top: -12px;
    `}
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  button {
    font-size: 1rem;
    padding: 5px 10px;
  }
`;

const ImageLabel = styled.label`
  display: block;
`;

const PhotoText = styled.p`
  font-size: 1.1rem;
  padding: 10px;
`;

const EditWrapper = styled.div`
  background-color: #fff;
  z-index: 100;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  ${({ theme }) => theme.media.min.tablet`
    position: absolute;
    bottom: -80px;
    left: 5%;
    width: 90%;
  `}
  label {
    display: flex;
    align-items: center;
    > span {
      font-size: 0.9rem;
    }
  }
`;
