/**
 *
 * Details Formik validators
 *
 *
 */

export default (values, setDisableContinue) => {
  let errors = {};

  if (!values.name) {
    errors.name = 'Name is required.';
  } else if (
    parseInt(values.birthDate.year) > parseInt(values.deathDate.year)
  ) {
    errors.birthDate = 'Birth date is more recent than Death date.';
  }

  if (Object.keys(errors).length > 0) {
    setDisableContinue(true);
  } else {
    setDisableContinue(false);
  }

  return errors;
};
