/**
 *
 * EditIcon (SVG)
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

const Edit = ({ fill, width = '100%', height = '100%', style = {} }) => {
  return (
    <svg
      height={height}
      width={width}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
    >
      <path
        fill={fill}
        d="M3.161 63.357c.471 0 .968-.115 1.479-.342l14.346-6.376c1.234-.549 2.887-1.684 3.843-2.64L62 14.829c.754-.754 1.17-1.759 1.17-2.829S62.754 9.925 62 9.172l-7.172-7.173C54.074 1.246 53.07.831 52 .831S49.926 1.246 49.172 2L9 42.171c-.968.967-2.09 2.651-2.612 3.917L.912 59.389c-.594 1.444-.174 2.42.129 2.873.466.696 1.239 1.095 2.12 1.095zM20 51.171s0 .001 0 0L12.828 44 46 10.828 53.172 18 20 51.171zM52 4.828L59.172 12 56 15.172 48.828 8 52 4.828zM10.088 47.611c.059-.142.138-.303.226-.469l6.213 6.213-10.776 4.788 4.337-10.532z"
      />
    </svg>
  );
};

Edit.propTypes = SVGPropTypes;

export default Edit;
