/**
 *
 * Share Ripspot
 *
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Button from 'components/Elements/Button';

import Desktop from 'components/utils/Desktop';
import Mobile from 'components/utils/Mobile';
import SocialShare from 'components/SocialShare';

import ShareGallery from './ShareGallery';

const Share = ({ ripspotID, shareViewData, orderDetails }) => {
  const [value, setValue] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [deathYear, setDeathYear] = useState('');
  const [shareURL, setShareURL] = useState('');

  const { details } = shareViewData;
  const { name, birthDate, deathDate } = details;
  const [lineItemPrices, setLineItemPrices] = useState({});

  const titleRef = React.createRef();

  useEffect(() => {
    //
    // form the url that will be used as the ripspots url link for sharing
    //
    const MEMORIAL_BASE =
      `${window.location.host}/ripspot` || 'https://ripspot.com/ripspot';
    const newURL = `${MEMORIAL_BASE}/${ripspotID}`; // TODO: once we finish memorial page issue, fix this, we use id for the generated pages, need to look into when the page does not exist at runtime and serve it a different way locally via fetching data
    setShareURL(newURL);

    //
    // form Birth and Death years
    //
    setBirthYear(birthDate.year);
    setDeathYear(deathDate.year);
    titleRef.current.scrollIntoView();

    handlePrices();
  }, []);

  const handlePrices = () => {
    const baseSetupPrice = 39;
    const baseHostingPrice = 10;

    const lineItemPrices = {
      totalPrice: orderDetails.amountPaid * 0.01,
      couponCode: orderDetails.couponCode,
      percentOff: orderDetails.percentOff,
    };
    setLineItemPrices(lineItemPrices);
  };

  const oneYear = new Date();
  oneYear.setFullYear(oneYear.getFullYear() + 1);
  return (
    <Container ref={titleRef} className="SHARE-container">
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h2>{name || 'No Name'}</h2>
        <h3 style={{ marginTop: '10px', marginBottom: '15px' }}>
          {birthYear}-{deathYear}
        </h3>
        <ShareGallery shareViewData={shareViewData} />
      </div>
      <div>
        <SocialShare
          name={name}
          birthYear={birthYear}
          deathYear={deathYear}
          shareURL={shareURL}
        />
      </div>
      {/* <OrderDetails className="order-details">
        <h4>Order Details:</h4>
        {lineItemPrices.couponCode && (
          <Text>
            You've applied coupon <strong>'{lineItemPrices.couponCode}'</strong>{' '}
            for a {lineItemPrices.percentOff}% discount.
          </Text>
        )}
        {lineItemPrices && (
          <LineItemList>
            <LineItem>
              <strong>One-Time Fee: </strong> $
              {lineItemPrices.totalPrice &&
                lineItemPrices.totalPrice.toFixed(2)}
            </LineItem>
            <LineItem>
              <strong>Hosting Fee: </strong> $
              {lineItemPrices.finalHostingPrice &&
                lineItemPrices.finalHostingPrice.toFixed(2)}
              /year
            </LineItem>
            <LineItem>
              <strong>Total:</strong>$
              {lineItemPrices.totalPrice &&
                lineItemPrices.totalPrice.toFixed(2)}
            </LineItem>
          </LineItemList>
        )}
        <Text>
          Your next payment of{' '}
          <strong>
            $
            {lineItemPrices.finalHostingPrice &&
              lineItemPrices.finalHostingPrice.toFixed(2)}
          </strong>{' '}
          will be charged on <strong>{oneYear.toLocaleDateString()}.</strong> If
          you have questions, would like to change your payment method or cancel
          your subscription, feel free to contact us{' '}
          <a href="mailto:support@ripspot.com">here</a>.
        </Text>
      </OrderDetails> */}
    </Container>
  );
};

Share.propTypes = {
  viewMapClick: PropTypes.func.isRequired,
  shareViewData: PropTypes.any.isRequired,
};

export default Share;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 15px;
  padding-left: 30px; /** this aligns left side with animated modal title */
  padding-right: 30px; /** this aligns left side with animated modal title */

  h2 {
    font-size: 2.6rem;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.Primary};
  }

  h3 {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.darkGrey};
  }
  > div {
    margin: 10px 0;
  }
  .order-details {
    margin-top: 4em;
  }
`;

const Text = styled.p`
  max-width: 600px;
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.Primary};
  line-height: 150%;
  a {
    text-decoration: underline;
  }
  @media all and (min-width: 630px) {
    padding: 0;
  }
`;

const OrderDetails = styled.div`
  width: 100%;
  max-width: 600px;
  h4 {
    font-size: 2.4em;
    color: ${({ theme }) => theme.colors.Primary};
    font-weight: 700;
  }
  > p {
    margin: 1em 0;
  }
`;

const LineItemList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const LineItem = styled.li`
  margin: 1em 0;
  display: flex;
  font-size: 2em;
  display: flex;
  align-items: flex-end;
  font-family: 'Montserrat', sans-serif;
  color: ${({ theme }) => theme.colors.Primary};
  padding: 1.5em 0.5em;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid ${({ theme }) => theme.colors.Primary};
  strong {
    font-size: 1.1em;
    font-weight: 700;
    margin-right: 1em;
    font-family: 'Libre Baskerville', serif;
  }
`;

const Note = styled.span`
  font-size: 1.4em;
  color: ${({ theme }) => theme.colors.Primary};
  font-style: italic;
  line-height: 2em;
  strong {
    font-weight: 700;
  }
`;
