/**
 *
 * SlickSlider
 *
 *
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { slickDots } from './styles';

const defaultSettings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  // centerMode: true,
  rows: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
        centerMode: true,
      },
    },
  ],
};

/**
 * SlickSlider
 *
 * @param {*} { slides, renderSlide, setRef, settings = {}, minHeight }
 * @returns
 */
const SlickSlider = ({
  slides,
  renderSlide,
  setRef,
  settings = {},
  minHeight,
}) => {
  const slider = useRef();

  if (setRef && slider.current) {
    // this allows us to grab slider instance ref from parent
    setRef.current = slider.current;
  }

  return (
    <StyledSlider
      ref={slider}
      minHeight={minHeight}
      {...defaultSettings}
      {...settings}
    >
      {slides &&
        slides.map((slide, index) => {
          if (renderSlide) {
            return renderSlide(slide, index);
          }
          return (
            <div key={index}>
              <h3>{index}</h3>
            </div>
          );
        })}
    </StyledSlider>
  );
};

SlickSlider.propTypes = {
  settings: PropTypes.any,
  setRef: PropTypes.any,
  minHeight: PropTypes.number,
  renderSlide: PropTypes.func,
  slides: PropTypes.arrayOf(PropTypes.any),
};

export default SlickSlider;

// default slider height, sets slide and slider container minheights
const SLIDER_HEIGHT = 260;

const StyledSlider = styled(Slider)`
  min-height: ${({ minHeight }) => minHeight || SLIDER_HEIGHT}px;
  padding-top: 30px;

  ${({ theme }) => theme.media.min.mobile`
    padding-top: 60px;
  `}

  .slick-list {
    height: 100%;
    width: 100%;
    overflow: visible;
  }

  .slick-track {
    height: 100%;
    width: 100%;
  }

  ${slickDots}

  .slick-slide {
    min-height: ${({ minHeight }) => minHeight || SLIDER_HEIGHT}px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;
