/**
 *
 * Quill Rich Text Editor
 *
 *
 *
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';


import styled, { css } from 'styled-components';
import 'react-quill/dist/quill.snow.css'; 


/**
 * Quill Custom Wrapper
 *
 * @param {*} { onEditChange, initialEditState }
 * @returns
 */
const Quill = ({ onEditChange, initialEditState }) => {
  const [editorState, setEditorState] = useState(initialEditState);

  useEffect(() => {
    setEditorState(initialEditState)
  }, [initialEditState])

  const editorRef = React.createRef();

  const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;


  const onChange = newEditorState => {
    setEditorState({text: newEditorState});
    onEditChange({text: newEditorState});
  }

  const modules = {
    keyboard: {
      //bindings: bindings
    },
    toolbar: [
      ['bold','italic',],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ]
  };

  const formats = [
    'bold', 'italic', 'underline',
    'list', 'bullet', 'indent',
    'link',
  ];


  // 
  return (
    <Container className="editorContainer">
      <EditorWrapper ref={editorRef}>
        <ReactQuill 
          value={editorState.text}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder="Tell their story..."
          ref={editorRef}
        />
      </EditorWrapper>
    </Container>
  );
};

Quill.propTypes = {
  onEditChange: PropTypes.func.isRequired,
  initialEditState: PropTypes.object,
};

export default Quill;

const Container = styled.div`
  ${({ theme }) => theme.styles.grow}
  position: relative;
  width: 100%;
  overflow-y: hidden;
`;
/* overflow-y: hidden;  */

const EditorWrapper = styled.div`
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  /* font-size: 14px; */
  height: 100%;
  padding: 15px;
  padding-bottom: 3em;
  border: 1px solid #222;
  overflow-y: scroll;

  .ql-editor.ql-blank {
    &:before {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    }
  }

  .ql-container.ql-snow {
    font-size: inherit;
    margin-top: 35px;
    border-top: 1px solid #ccc !important;
  }
  
  .ql-clipboard {
    position: fixed;
  }

  .ql-toolbar {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    z-index: 2;
  }

  p {
    font-size: 1.6em;
    strong {
      font-weight: 700;
    }
    em {
      font-style: italic;
    }
  }
  ul,ol {
    li {
      font-size: 1.8em;
      line-height: 1.5em;
      font-weight: 400;
      font-family: 'Montserrat', sans-serif;
      strong {
        font-weight: 700;
      }
      em {
        font-style: italic;
      }
    }
  }
`;

const ToolbarWrapper = styled.div`
  padding: 10px 15px;
  width: 100%;
  border: 1px solid #222;
  border-bottom: none;
  button {
    cursor: pointer;
  }
  > div {
    display: flex;
    width: 100%;
    > div {
      width: 100%;
      display: flex;
    }
  }
`;

