/**
 *
 * CheckoutFormStripe
 *
 * * This is a working version!!! utlizes the new @stripe/ lib
 *
 *  */

import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import Button from 'components/Elements/Button';

// import CardSection from './CardSection';
import useResponsiveFontSize from './useResponsiveFontSize';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: '#000',
          letterSpacing: '0.025em',
          fontFamily: "'Montserrat', sans-serif",
          '::placeholder': {
            color: '#C4C0BB',
          },
          fontSmoothing: 'antialiased',
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [fontSize],
  );

  return options;
};

/**
 * CheckoutFormStripe
 *
 * @param {*} props
 * @returns
 */
export const CheckoutFormStripe = ({
  onHandlePayment,
  onHandleError,
  completePurchase,
  setDisableContinue,
}) => {
  const [localState, setLocalState] = useState({
    nameOnCard: '',
    zipcode: '',
    couponCode: '',
    // stripe
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    cardNumberError: '',
    cardExpiryError: '',
    cardCvcError: '',
    couponError: '',
  });

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async evt => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    evt && evt.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.

      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    let chargeToken;
    try {
      const { token } = await stripe.createToken(cardElement);
      chargeToken = token;
    } catch (tokenError) {}

    // const { token } = await stripe.createToken({
    //   name: localState.nameOnCard,
    //   address_zip: localState.zipcode,
    // });

    //
    //

    if (error) {
      onHandleError(error);
    } else {
      // good to go!
      onHandlePayment(paymentMethod, chargeToken, localState.couponCode, stripe);
    }
  };

  useEffect(() => {
    if (completePurchase) {
      // // eslint-disable-next-line
      // alert('Submitting form!!!');

      handleSubmit(); // we dont need to pass an event object since we trigger this manually
    }
  }, [completePurchase]);

  //
  // grab values from state to determine if form is filled out or not
  //
  const {
    nameOnCard,
    zipcode,
    cardNumber,
    cardExpiry,
    cardCvc,
    couponCode,
  } = localState;

  const validateFields = () => {
    if (nameOnCard && zipcode && cardNumber && cardExpiry && cardCvc) {
      setDisableContinue(false);
    } else {
      setDisableContinue(true);
    }
  };

  useEffect(() => {
    // validate form on all changes
    validateFields();
  }, [nameOnCard, zipcode, cardNumber, cardExpiry, cardCvc]);

  //
  //
  // TODO: feed initial values into these special components
  //
  //
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <FieldWrapper>
          <label>
            <span>Name On Card</span>
            <Input
              value={localState.nameOnCard}
              onChange={evt => {
                setLocalState({
                  ...localState,
                  nameOnCard: evt.target.value,
                });
              }}
              name="nameOnCard"
              type="text"
              placeholder="Your Name Here..."
              className="StripeElement"
              required
            />
          </label>
        </FieldWrapper>
        <FieldWrapper>
          <label>
            <span>Card number</span>
            <CardNumberElement
              options={options}
              onChange={event => {
                if (event.error) {
                  setLocalState({
                    ...localState,
                    cardNumberError: event.error.message,
                  });
                }
                //
                if (event.complete) {
                  setLocalState({
                    ...localState,
                    cardNumber: true,
                    cardNumberError: '',
                  });
                }
              }}
            />
          </label>
          {localState.cardNumberError !== '' && (
            <ErrorMessage>{localState.cardNumberError}</ErrorMessage>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <label>
            <span>Expiration date</span>
            <CardExpiryElement
              options={options}
              onChange={event => {
                if (event.error) {
                  setLocalState({
                    ...localState,
                    cardExpiryError: event.error.message,
                  });
                }
                //
                if (event.complete) {
                  setLocalState({
                    ...localState,
                    cardExpiry: true,
                    cardExpiryError: '',
                  });
                }
              }}
            />
          </label>
          {localState.cardExpiryError !== '' && (
            <ErrorMessage>{localState.cardExpiryError}</ErrorMessage>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <label>
            <span>CVC</span>
            <CardCvcElement
              options={options}
              onChange={event => {
                if (event.error) {
                  setLocalState({
                    ...localState,
                    cardExpiryError: event.error.message,
                  });
                }
                //
                if (event.complete) {
                  setLocalState({
                    ...localState,
                    cardCvc: true,
                    cardCvcError: '',
                  });
                }
              }}
            />
          </label>
          {localState.cardCvcError !== '' && (
            <ErrorMessage>{localState.cardExpiryError}</ErrorMessage>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <label>
            <span>Zipcode</span>
            <Input
              value={localState.zipcode}
              onChange={evt => {
                setLocalState({
                  ...localState,
                  zipcode: evt.target.value,
                });
              }}
              name="zipcode"
              type="text"
              placeholder="Zipcode"
              className="StripeElement"
              required
            />
          </label>
        </FieldWrapper>
        <FieldWrapper>
          <label>
            <span>Coupon Code</span>
            <Note>Note: Coupon value will be applied to your payment.</Note>
            <Input
              value={localState.couponCode}
              onChange={evt => {
                setLocalState({
                  ...localState,
                  couponCode: evt.target.value,
                });
              }}
              name="couponCode"
              type="text"
              placeholder="Coupon Code"
              className="StripeElement"
            />
          </label>
          {localState.couponError !== '' && (
            <ErrorMessage>{localState.cardExpiryError}</ErrorMessage>
          )}
        </FieldWrapper>
        {/* <Button type="submit" disabled={!stripe}>
          Pay
        </Button> */}
      </form>
    </div>
  );
};

CheckoutFormStripe.propTypes = {
  onHandlePayment: PropTypes.func.isRequired,
  onHandleError: PropTypes.func.isRequired,
  completePurchase: PropTypes.bool.isRequired,
  setDisableContinue: PropTypes.func.isRequired,
};

export default CheckoutFormStripe;

const FieldWrapper = styled.div`
  margin-bottom: 15px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  label {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    span {
      display: inline-block;
      padding-bottom: 5px;
    }
  }
  .StripeElement {
    padding: 8px;
    line-height: 2.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .InputElement {
    font-family: 'Montserrat', sans-serif !important;
  }
`;

const Input = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  &::placeholder {
    color: #c4c0bb;
  }
`;

const Note = styled.span`
  font-size: 13px;
  width: 100%;
  font-style: italic;
  margin-bottom: 0.25em;
  line-height: 1.35;
`;

const ErrorMessage = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  color: #9e2146;
  margin: 0.25em 0;
`;

// const BottomFields = styled.div`
//   display: flex;
//   flex-wrap: wrap;

//   > div {
//     /* flex: 1; */
//     width: 50%;
//   }
// `;
