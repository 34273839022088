/**
 *
 * Ripspot Modal
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring';

const AnimatedTitle = ({
  step,
  steps,
  prevStep,
  animateUpDown = false,
  includeStep = true,
  isPayment = false,
  isReview = false,
}) => {
  // eslint-disable-next-line no-use-before-define
  const numberTransitions = useNumberTransition({ step, prevStep }); // defined below
  // eslint-disable-next-line no-use-before-define
  const titleTransitions = useTitleTransition({
    step,
    prevStep,
    animateUpDown,
  });

  return (
    <Container isReview={isReview} isPayment={isPayment}>
      {includeStep &&
        numberTransitions.map(({ item, props, key }) => (
          <AnimatedNumber key={`step-number-${key}`} style={props}>
            {item}.
          </AnimatedNumber>
        ))}
      {titleTransitions.map(({ item, key, props }) => (
        <animated.div key={`title-${key}`} style={props}>
          <Title isReview={isReview}>{steps[item].title}</Title>
        </animated.div>
      ))}
    </Container>
  );
};

AnimatedTitle.propTypes = {
  step: PropTypes.number.isRequired,
  steps: PropTypes.object.isRequired,
  prevStep: PropTypes.number,
  animateUpDown: PropTypes.bool,
  includeStep: PropTypes.bool,
  isPayment: PropTypes.bool,
  isReview: PropTypes.bool,
};

export default AnimatedTitle;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
  transition: height 0.5s;
  height: ${({ isReview, isPayment }) => (isReview || isPayment ? 60 : 10)}px;
  ${({ theme, isReview, isPayment }) =>
    (isReview || isPayment) &&
    theme.media.max.tablet`
      height: 50px;
  `}
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  text-align: center;
  ${({ theme }) => theme.media.min.mobile`
    font-size: 1.35rem;
  `}
  ${({ theme }) => theme.media.min.tablet`
    font-size: 1.6rem;
  `}
  ${({ isReview, theme }) =>
    isReview &&
    theme.media.min.tablet`
      line-height: 60px;
    `}
  
  
`;

const AnimatedNumber = styled(animated.div)`
  font-size: 1rem;
  ${({ theme }) => theme.media.min.mobile`
    font-size: 1.35rem;
  `}
  ${({ theme }) => theme.media.min.tablet`
    font-size: 2rem;
  `}
`;

/**
 * Number transition via react-spring's useTransition
 *
 * @param {*} { step, prevStep }
 * @returns
 */
function useNumberTransition({ step, prevStep }) {
  const numberTransitions = useTransition(step, key => key, {
    from: {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      transform: `translate3d(0,${step > prevStep ? '' : '-'}20px,0)`,
    },
    enter: {
      opacity: step === 8 ? 0 : 1,
      transform: 'translate3d(0,0px,0)',
    },
    leave: {
      opacity: 0,
      transform: `translate3d(0,${step > prevStep ? '-' : ''}20px,0)`,
    },
  });
  return numberTransitions;
}

/**
 * Title transition via react-spring's useTransition
 *
 * @param {*} { animateUpDown, step, prevStep }
 * @returns
 */
function useTitleTransition({ animateUpDown, step, prevStep }) {
  //
  const titleTransitions = useTransition(step, key => key, {
    from: {
      position: 'absolute',
      top: 0,
      left: 20,
      // width: 400,
      transform: animateUpDown
        ? `translate3d(0,${step > prevStep ? '-' : ''}10px,0)`
        : `translate3d(${step > prevStep ? '' : '-'}20px,0,0)`,
      opacity: 0,
    },
    enter: {
      opacity: step === 8 ? 0 : 1,
      transform: 'translate3d(0,0,0)',
    },
    leave: {
      opacity: 0,
      transform: animateUpDown
        ? `translate3d(0,${step > prevStep ? '' : '-'}10px,0)`
        : `translate3d(${step > prevStep ? '-' : ''}20px,0,0)`,
    },
  });
  return titleTransitions;
}
