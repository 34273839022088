/* eslint-disable no-restricted-properties */
/**
 *
 * Image Utilities
 *
 *
 */
import Resizer from 'react-image-file-resizer';

/**
 *
 *
 * @param {*} file
 */
export const getBase64 = file =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = onLoadEvt => {
        const src = onLoadEvt.target.result;
        resolve(src);
      };
      reader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });

const IMAGE_MAX_WIDTH = 1200;
const IMAGE_MAX_HEIGHT = 1200;

/**
 * Accepts only JPG/JPEG/PNG images and resizes to user opts or defaults to max resolution of 300
 *
 * @param {*} file
 * @param {*} [opts={
 *  maxWidth,
 *  maxHeight,
 *  quality,
 *  rotation
 * }]
 */
export const resizeImageFile = (file, opts = {}) =>
  new Promise((resolve, reject) => {
    // const isJPEG = file.type.includes('jpeg');
    // const isAlsoJPEG = file.type.includes('jpg');
    const isPNG = file.type.includes('png');
    const imageType = isPNG ? 'PNG' : 'JPEG'; // (isJPEG || isAlsoJPEG) && 'JPEG';
    try {
      Resizer.imageFileResizer(
        file,
        opts.maxWidth || IMAGE_MAX_WIDTH,
        opts.maxHeight || IMAGE_MAX_HEIGHT,
        imageType, // compressFormat: Can be either JPEG, PNG or WEBP.
        opts.quality || 80, // quality: 0 - 100
        opts.rotation || 0, // rotation: (0, 90, 180, 270, 360)
        uri => {
          //
          return resolve({ uri });
        },
        'base64', // Can be either base64 or blob
      );
    } catch (resizeImageFileErr) {
      return reject(resizeImageFileErr);
    }
  });

/**
 * Validates files by size and returns as two arrays, 'valid' and 'tooBig'
 *
 * @param {*} files
 * @returns {{valid=[], tooBig=[]}}
 */
export const validateFiles = files => {
  if (!files.length) return { valid: [], tooBig: [] };

  let valid = [];
  let tooBig = [];

  // TODO: get the real limit from Max!!!
  const bytesLimit = 10000000;
  // loop through files
  for (let i = 0; i < files.length; i++) {
    //  get item
    //  const file = files.item(i);
    // or
    const file = files[i];

    if (file.size > bytesLimit) {
      // optionally process some UX about this file size
      // display file too large message
      tooBig = [...tooBig, file];
    } else {
      valid = [...valid, file];
    }
  }

  return {
    valid,
    tooBig,
  };
};

/**
 * Takes dataURI and converts it back to a file
 *
 * allows for resizing images and re-creating them as a file again for uploading to S3
 *
 * @export
 * @param {*} dataurl
 * @param {*} filename
 * @returns
 */
export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

/**
 * Formats bytes into appropriate unit size
 *
 * @export
 * @param {*} bytes
 * @param {number} [decimals=2]
 * @returns
 */
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${SIZES[i]}`;
}

/**
 *
 * convert bytes for higher orders of magnitude / unit size
 *
 * */
const GB_BYTES = 1073741824;
const MB_BYTES = 1048576;
const KB_BYTES = 1024;

/**
 * Formats bytes into appropriate unit size
 *
 * @export
 * @param {*} bytes
 * @returns
 */
export function formatSizeUnits(bytes) {
  if (bytes >= GB_BYTES) {
    bytes = `${(bytes / GB_BYTES).toFixed(2)} GB`;
  } else if (bytes >= MB_BYTES) {
    bytes = `${(bytes / MB_BYTES).toFixed(2)} MB`;
  } else if (bytes >= KB_BYTES) {
    bytes = `${(bytes / KB_BYTES).toFixed(2)} KB`;
  } else if (bytes > 1) {
    bytes += ' bytes';
  } else if (bytes === 1) {
    bytes += ' byte';
  } else {
    bytes = '0 bytes';
  }
  return bytes;
}
