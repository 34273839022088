/**
 *
 * Mapbox  react-mapbox-gl
 *
 ** [lng, lat] --> format of coords is [longitude, latitude]
 *
 */

import React, { memo, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMapboxGl, { ZoomControl, RotationControl } from 'react-mapbox-gl';
import Helmet from 'react-helmet';
import * as mapboxGl from 'mapbox-gl';
import styled from 'styled-components';

// import CloseButton from 'components/CloseButton';

import { HEADER_HEIGHT } from 'components/Header';
import { CONTROLS_HEGHT } from 'containers/ExploreMap/Controls';

import { MapStatePropTypes } from 'utils/propTypes';
import { streetStyle, satelliteStyle } from 'utils/helperFunctions/maps';

import { US } from 'utils/globals';
import { uploadIcons } from './utils';
import { unstable_batchedUpdates } from 'react-dom';
// import CustomMarker from './Marker';

// Starbucks, Doylestown PA
// const defaultCenter = [-75.130841, 40.309922];

const API_KEY = process.env.GATSBY_MAPBOX_API_KEY;

// injects CSS
const MapBoxGL = ReactMapboxGl({
  logoPosition: 'bottom-right',
  attributionControl: false,
  doubleClickZoom: false,
  accessToken: API_KEY,
});

const Mapbox = ({
  mapState,
  // height = 'auto',
  height = '100%',
  width = '100%',
  children,
  onStyleLoad,
  demo = false,
  dragPan,
  isFullScreen = false,
  onClick,
  ...props
}) => {
  const [showMap, setShowMap] = useState(true);
  const map = useRef();

  // const viewport = demo ? null : mapState && mapState.viewport;
  const isSatellite = !demo && mapState && mapState.style === satelliteStyle;

  useEffect(() => {
    
    
    // this is how we retain layers/features when map style has changed
    setShowMap(false);
    setTimeout(() => {
      setShowMap(true);

      // if (map.current) {
      //   //
      //   const marker = new mapboxGl.Marker({
      //     draggable: true,
      //   })
      //     .setLngLat(US)
      //     .addTo(map.current);

      //   
      // }
    }, 500); // 200
  }, [isSatellite, map]);

  // for some reason, mapState is undefined when map first starts up even though it has an init value from parent containers
  if (!demo && !mapState) {
    // return null until map style is ready
    return null;
  }

  const containerStyle = isFullScreen
    ? {
        height: `calc(100vh - ${HEADER_HEIGHT}px - ${CONTROLS_HEGHT}px)`,
        width: '100vw',
      }
    : {
        height,
        width,
      };

  if (isSatellite) {
    containerStyle.backgroundColor = 'black';
  } else {
    containerStyle.backgroundColor = 'rgb(239,233,225)';
  }

  const style = demo ? streetStyle : mapState.style;

  const mapInitCenter = [
    (mapState && mapState.viewport.longitude) || US[0],
    (mapState && mapState.viewport.latitude) || US[1],
  ];
  const mapInitZoom = (mapState && mapState.viewport.zoom) || 3.5;

  // 
  // 
  // 
  // 



  return (
    <MapWrapper style={containerStyle}>
      <Helmet>
        {/* This is coming from a warning in 
            link and it says we are missing css needed for the
            base mapbox-gl lib...but warning still persists         */}
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.css"
          rel="stylesheet"
        />
      </Helmet>
      {showMap && (
        <>
          <MapBoxGL
            // visible={showMap}
            style={style}
            // style="mapbox://styles/ripspot/cjydk1erg346c1cqkhr4evvbh"
            containerStyle={containerStyle}
            center={mapInitCenter} // initial center / coords
            zoom={[mapInitZoom]} // initial zoom
            onClick={onClick}
            onStyleLoad={_map => {
              // 
              // keep map ref so we can target instance outside return call (resetting images after map style change)
              map.current = _map;
              uploadIcons({ map: _map });
              onStyleLoad && onStyleLoad(_map);
            }}
            // maxZoom={viewport.maxZoom}
            {...props}
          >
            {children}
            <ZoomControl style={{right: 10, bottom: 35, top: `unset`, left: `unset` }}/>
            <RotationControl style={{right: 10, bottom: 90, left: `unset`, top: `unset`  }}/>
          </MapBoxGL>
        </>
      )}
    </MapWrapper>
  );
};

Mapbox.propTypes = {
  dragPan: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  mapState: MapStatePropTypes,
  children: PropTypes.any,
  demo: PropTypes.bool,
  onStyleLoad: PropTypes.func,
  onClick: PropTypes.func,
  // isSatellite: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default memo(Mapbox);

const MapWrapper = styled.div`
  transition: background-color 0.1s ease-in-out;
`;

// const PopupContent = styled.div`
//   color: #000;
//   padding: 35px;
//   max-width: 300px;
//   max-height: 200px;
//   font-size: 0.8rem;
// `;
/* ${({ theme }) => theme.styles.ellipsisMask}; */

// supported EVENTS

// eslint-disable-next-line no-unused-vars
const events = {
  // Triggered when style of the map has loaded
  onStyleLoad: '',

  // mapbox-gl events
  onResize: 'resize',
  onDblClick: 'dblclick',
  onClick: 'click',
  onMouseMove: 'mousemove',
  onMouseOut: 'mouseout',
  onMoveStart: 'movestart',
  onMove: 'move',
  onMoveEnd: 'moveend',
  onMouseUp: 'mouseup',
  onMouseDown: 'mousedown',
  onDragStart: 'dragstart',
  onDrag: 'drag',
  onDragEnd: 'dragend',
  onZoomStart: 'zoomstart',
  onZoom: 'zoom',
  onZoomEnd: 'zoomend',
  onPitch: 'pitch',
  onPitchStart: 'pitchstart',
  onPitchEnd: 'pitchend',
  onWebGlContextLost: 'webglcontextlost',
  onWebGlContextRestored: 'webglcontextrestored',
  onRemove: 'remove',
  onContextMenu: 'contextmenu',
  onRender: 'render',
  onError: 'error',
  onSourceData: 'sourcedata',
  onDataLoading: 'dataloading',
  onStyleDataLoading: 'styledataloading',
  onStyleImageMissing: 'styleimagemissing',
  onTouchCancel: 'touchcancel',
  onData: 'data',
  onSourceDataLoading: 'sourcedataloading',
  onTouchMove: 'touchmove',
  onTouchEnd: 'touchend',
  onTouchStart: 'touchstart',
  onStyleData: 'styledata',
  onBoxZoomStart: 'boxzoomstart',
  onBoxZoomEnd: 'boxzoomend',
  onBoxZoomCancel: 'boxzoomcancel',
  onRotateStart: 'rotatestart',
  onRotate: 'rotate',
  onRotateEnd: 'rotateend',
};

// return (
//   <Map
//     // visible={showMap}
//     // eslint-disable-next-line react/style-prop-object
//     style={
//       isSatellite
//         ? 'mapbox://styles/mapbox/satellite-v9'
//         : 'mapbox://styles/mapbox/streets-v9'
//     }
//     // style="mapbox://styles/ripspot/cjydk1erg346c1cqkhr4evvbh"
//     containerStyle={containerStyle}
//     center={center}
//     zoom={[zoom]}
//     onStyleLoad={_map => {
//       
//       // keep map ref so we can target instance outside return call (resetting images after map style change)
//       map.current = _map;
//       uploadIcons({ map: _map });
//       onStyleLoad && onStyleLoad(_map);
//     }}
//     // maxZoom={viewport.maxZoom}
//     {...props}
//   >
//     {demo && (
//       <CustomMarker
//         showPoint
//         coords={defaultCenter}
//         renderPopup={() => (
//           <div>
//             <h3>Starbucks, Doylestown PA</h3>
//           </div>
//         )}
//       />
//     )}
//     {children}
//   </Map>
// );
