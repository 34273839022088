/**
 *
 * ToggleButton
 *
 * */

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const ToggleButton = ({ onToggle, disabled, initLeftToggle = true }) => {
  const [isLeftToggled, setIsLeftToggled] = useState(initLeftToggle);
  const toggle = title => evt => {
    onToggle && onToggle(title);
    !disabled && setIsLeftToggled(title === 'annual');
  };
  return (
    <Container>
      <ButtonSide onClick={toggle('annual')} isActive={isLeftToggled}>
        annual
      </ButtonSide>
      <ButtonSide onClick={toggle('one-time')} isActive={!isLeftToggled}>
        one-time
      </ButtonSide>
    </Container>
  );
};

ToggleButton.propTypes = {
  initLeftToggle: PropTypes.bool,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default memo(ToggleButton);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 3px solid ${({ theme }) => theme.colors.lightGreen};
`;

const ButtonSide = styled.button`
  color: ${({ theme }) => theme.colors.lightGreen};
  margin: 0;
  height: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
  background-color: white;
  height: 1.5em;
  &:hover {
    color: ${({ theme }) =>
      theme.colors.alphaMod(theme.colors.lightGreen, 0.8)};
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.lightGreen};
      color: #fff;

      &:hover {
        color: #666;
        background-color: ${({ theme }) =>
          theme.colors.alphaMod(theme.colors.lightGreen, 0.8)};
      }
    `}
`;
