/**
 *
 * Validators for RestPasswordFormik
 *
 *
 */

export default values => {
  const errors = {};

  switch (true) {
    case !values.password:
      errors.password = 'Required';
      break;
    case !values.rePassword:
      errors.rePassword = 'Required';
      break;
    case values.password !== values.rePassword:
      errors.password = 'Passwords must match';
      break;
    default:
  }

  return errors;
};
