/**
 *
 * Mapbox utils
 *
 */

import RipspotPinX400 from 'images/ripspot-pin/ripspot-pin-400px.png';
import RipspotPinX200 from 'images/ripspot-pin/ripspot-pin-200px.png';
import RipspotPinX100 from 'images/ripspot-pin/ripspot-pin-100px.png';
import RipspotPinX50 from 'images/ripspot-pin/ripspot-pin-50px.png';

import icons from './icons';

var BASE64_MARKER = ';base64,';

function convertDataURIToBinary(dataURI) {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for(let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

export const uploadIcons = ({ map }) => {
  map.loadImage(RipspotPinX400, (err, image) => {
    
    if (err) console.error(err);
    map.addImage('ripspotpin-lg', image);
  });
  map.loadImage(RipspotPinX200, (err, image) => {
    
    if (err) console.error(err);
    map.addImage('ripspotpin-md', image);
  });
  map.loadImage(RipspotPinX100, (err, image) => {
    
    if (err) console.error(err);
    map.addImage('ripspotpin-sm', image);
  });
  map.loadImage(RipspotPinX50, (err, image) => {
    
    if (err) console.error(err);
    map.addImage('ripspotpin-xs', image);
  });

  icons.forEach(({ image, name }) => {
    map.loadImage(image, (err, imageData) => {
      if (err) console.error(err);
      imageData.data = convertDataURIToBinary(image);
      map.addImage(name, imageData);
    });
  });
  // map.on('styleimagemissing', (e) => {
  //   icons.map((icon) => {
  //     if (e.id === icon.name) {
  //       map.loadImage(icon.image, (err, imageData) => {
  //         imageData.data = convertDataURIToBinary(icon.image);
  //         map.addImage(icon.name, imageData);
  //       })
  //     }
  //   })
  // })
};
