/**
 *
 * * NEW ReviewRipspot COMPONENT
 *
 * ? Original mockups' component is still in directory under "OLD"
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import RipspotMemorial from 'components/RipspotMemorial';

// US
const US = [-96.90198636571415, 39.04995886321319];

const ReviewRipspot = ({ ripspot }) => {
  const { photoGallery, details, bio } = ripspot;

  //
  // format for memmorial page, even thjo we still need preview prop too
  //
  const newRipspot = {
    ...details,
    bio,
    photoGallery: Object.keys(photoGallery).map(photoKey => {
      const photoItem = photoGallery[photoKey];
      //
      //
      return photoItem;
    }),
  };

  // *
  // * NEW UX/UI!!!
  // *
  // *  Memorial Page Preview before user pays as the final step!!!
  // *
  // * We pass in the special prop, `preview`, so that the RipspotMemorial
  // * knows which data to render as the structures do not match the
  // * db model exactly so we must make this check and change the UI within
  // * RipspotMemorial for ease of reuse and not creating a new file with similiar styles etc.
  // *
  // *
  return <RipspotMemorial isPreview ripspot={newRipspot} />;
};

ReviewRipspot.propTypes = {
  ripspot: PropTypes.any.isRequired,
};

export default memo(ReviewRipspot);
