/**
 *
 * useReviewPaymentLogic
 *
 * */

import { useState, useEffect } from 'react';

// fixed prices
import { SUB_PRICE, SINGLE_CHARGE_PRICE } from 'utils/constants';

export default ({ setTempData, plan }) => {
  const [isAnnual, setIsAnnual] = useState(true);
  const [total, setTotal] = useState(
    isAnnual ? SUB_PRICE : SINGLE_CHARGE_PRICE,
  );

  // this handles updating total based on isAnnual var driven by toggle switch
  useEffect(() => {
    if (isAnnual) {
      setTotal(SUB_PRICE);
      setTempData('payment', {
        plan: 'annual',
        total: SUB_PRICE,
      });
    } else {
      setTotal(SINGLE_CHARGE_PRICE);
      setTempData('payment', {
        plan: 'one-time',
        total: SINGLE_CHARGE_PRICE,
      });
    }
  }, [isAnnual]);

  return {
    setIsAnnual,
    isAnnual,
    total,
  };
};
