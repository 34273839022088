/**
 *
 * Reset Password Modal
 *
 *
 */

import React, { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { closeResetPasswordModal } from 'actions/modals';

import Modal from 'components/modals/index';
import ResetPassword from 'containers/Auth/ResetPassword';
import useRouter from 'utils/hooks/useRouter';

const ResetPasswordModal = ({ isOpen, dispatch }) => {
  const onRequestClose = () => {
    dispatch(closeResetPasswordModal());
  };

  const { location } = useRouter();

  return (
    <Modal
      noMinMax
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title="Reset Password"
    >
      <Container>
        <ResetPassword location={location} navigate={navigate} />
      </Container>
    </Modal>
  );
};

ResetPasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ display, modals }) => ({
  display,
  isOpen: modals.passwordResetModal.isOpen,
});

const withConnect = connect(mapStateToProps);
export default compose(memo, withConnect)(ResetPasswordModal);

const Container = styled.div`
  height: 100%;
  padding: 25px;
`;
