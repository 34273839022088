/* eslint-disable no-use-before-define */
/**
 *
 * ResetPassword
 *
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import jwtDecode from 'jwt-decode';
import { useSpring, animated } from 'react-spring';

import { toastSuccess, toastError, toastWarn, toastInfo } from 'utils/toastify';
import { validateToken, resetUserPassword } from 'utils/api/users';
import ResetPasswordFormik from './ResetPasswordFormik';

const DX = 20;

const ResetPassword = ({ location, navigate }) => {
  const { user, tokenId } = useLogic({ location, navigate });

  const animatedProps = useSpring({
    opacity: user ? 1 : 0,
    transform: user ? 'translate3d(0,0,0)' : `translate3d(0,${-DX}px,0)`,
  });
  return (
    <Container>
      <animated.div style={animatedProps}>
        <Title>Reset Password</Title>
        <ResetPasswordFormik
          initialValues={{
            password: '',
            rePassword: '',
          }}
          // eslint-disable-next-line
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              const props = {
                tokenId,
                email: user.email,
                password: values.password,
              };
              const res = await resetUserPassword(props);

              setSubmitting(false);
              if (res) {
                navigate('/sign-in');
                toastSuccess('Password changed successfully!');
              }
            } catch (err) {
              console.error(err);
              setSubmitting(false);
              setFieldError(err.location, err.message);
              toastError(err.reason || 'Sorry, something went wrong.');
            }
          }}
        />
      </animated.div>
    </Container>
  );
};

ResetPassword.propTypes = {
  location: PropTypes.any.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default ResetPassword;

const Container = styled.div`
  color: #000;
  width: 100%;
  max-width: 600px;
  margin: 100px auto 0;
`;

const Title = styled.h2`
  color: #000;
  margin: 0;
  padding: 0px 15px;
`;

const decodeToken = token => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (err) {
    return null;
  }
};

function useLogic({ location, navigate }) {
  const [user, setUser] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const params = new URLSearchParams(location.search.substring(1));
  const token = params.get('token');

  useEffect(() => {
    if (token) {
      const decodedToken = decodeToken(token);

      if (decodedToken) {
        if (decodedToken.exp < Date.now() / 1000) {
          // token expired
          // redirect

          toastWarn('Token has expired!');
          navigate('/');
        } else {
          (async () => {
            // make call to verify token in db
            const { user: tokenUser, tokenId: _tokenId } = decodedToken;
            const isValid = await validateToken(_tokenId);
            if (isValid) {
              setUser(tokenUser);
              setTokenId(_tokenId);
            } else {
              // Server invalidated Token
              const msg = 'Token is invalid!';

              toastError(msg);
              navigate('/');
            }
          })();
        }
      } else {
        // INVALID JWT TOKEN
        const msg = 'Token is invalid!';

        toastError(msg);
        navigate('/');
      }
    }
  }, [token]);
  return { user, tokenId };
}
