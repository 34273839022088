/**
 *
 * Details
 *
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DetailsFormik from './DetailsFormik';
import validate from './DetailsFormik/validators';

const Details = ({
  setTempData,
  getTempData,
  dispatch,
  setDisableContinue,
}) => {
  const _tempData = getTempData();

  useEffect(() => {
    validate(_tempData.details, setDisableContinue);
  }, [_tempData.details]);

  return (
    <Container>
      <DetailsFormik
        setTempData={setTempData}
        getTempData={getTempData}
        dispatch={dispatch}
        setDisableContinue={setDisableContinue}
        initialValues={{
          name: (_tempData && _tempData.details.name) || '',
          birthDate: (_tempData && _tempData.details.birthDate) || '',
          deathDate: (_tempData && _tempData.details.deathDate) || '',
        }}
        validate={values => validate(values, setDisableContinue)}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          setSubmitting(false);
        }}
      />
    </Container>
  );
};

Details.propTypes = {
  setTempData: PropTypes.func.isRequired,
  getTempData: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  setDisableContinue: PropTypes.func.isRequired,
};

export default Details;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  @media all and (max-width: 800px) {
    padding-top: 6em;
  }
`;
