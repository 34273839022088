/**
 *
 * ShareGallery
 *
 * */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PhotoGallery from 'components/PhotoGallery';

const ShareGallery = ({ shareViewData }) => {
  const [slides, setSlides] = useState([]);

  const photoGallery = shareViewData && shareViewData.photoGallery;

  useEffect(() => {
    if (photoGallery) {
      const keys = Object.keys(photoGallery);
      const filtered = keys.map((key, index) => photoGallery[key]);
      setSlides(filtered);
    }
  }, [photoGallery]);

  return <PhotoGallery photoGallery={slides} minHeight={100} isReview />;
};

ShareGallery.propTypes = {
  shareViewData: PropTypes.shape({
    photoGallery: PropTypes.any,
  }),
};

export default ShareGallery;

// background: linear-gradient(
//   to right,
//   rgba(255, 255, 255, 1),
//   rgba(255, 255, 255, 1),
//   rgba(255, 255, 255, 0.5),
//   rgba(255, 255, 255, 0.1)
// );
